


















import Vue from "vue";
export default Vue.extend({
  name: "NextMoveCareersNotFound",
  props: {
    msg: {
      type: String,
      default: "Careers Not Found"
    }
  }
});
