















































import { get_portal_formatted_name } from "@/utils/global";
import Vue from "vue";
export default Vue.extend({
  name: "NextMoveStartDescription",
  data() {
    return {
      title: "Here's how it Works"
    };
  },
  methods: {
    get_portal_formatted_name
  }
});
