var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"next-move-wrapper"},[(_vm.data_loading)?[_c('next-move-loading')]:(_vm.data_error)?[_c('app-resourse-not-found',{attrs:{"title":_vm.data_error +
        '. Interest Area: ' +
        _vm.selected_interest_area +
        '  Job Zone: ' +
        _vm.selected_job_zone},on:{"refresh_process":_vm.reset_process}})]:(
      !_vm.selected_assessment_result ||
      !_vm.selected_assessment_result.result ||
      !_vm.selected_assessment_result.result.data
    )?[_c('app-resourse-not-found',{attrs:{"title":"Assessment result not found."},on:{"refresh_process":_vm.reset_next_move_state}})]:[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"pr-0 mr-0 pr-md-16 mr-md-16 mt-3 mt-md-6"},[_c('p',{staticClass:"base-section-sub-title"},[_vm._v(" Click to change your Interest Area: ")]),_c('v-autocomplete',{staticClass:"auto-complete-zone",attrs:{"value":_vm.selected_interest_area,"items":_vm.interest_areas,"elevation":"0","outlined":"","hide-details":"","placeholder":"Select Interest Area"},on:{"change":_vm.set_selected_interest_area},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-img',{attrs:{"src":_vm.dropdown_icon,"alt":"Select Interest Area"}})]},proxy:true},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('p',{staticClass:"base-body-text mb-0"},[_c('span',{staticClass:"base-section-sub-title"},[_vm._v(" "+_vm._s(item.text))]),_vm._v(" "+_vm._s(item.sub_text)+" ")])])]}},{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('p',{staticClass:"base-body-text mb-0"},[_c('span',{staticClass:"base-section-sub-title"},[_vm._v(" "+_vm._s(item.text))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}]},[_vm._v(_vm._s(item.sub_text))])])])]}}])})],1),_c('div',{staticClass:"pr-0 mr-0 pr-md-16 mr-md-16 mt-3 mt-md-6"},[_c('p',{staticClass:"base-section-sub-title"},[_vm._v("Click to change your Job Zone:")]),_c('v-autocomplete',{staticClass:"auto-complete-zone",attrs:{"value":_vm.selected_job_zone,"items":_vm.job_zones,"elevation":"0","outlined":"","hide-details":"","placeholder":"Select Job Zones"},on:{"change":_vm.set_selected_job_zone},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-img',{attrs:{"src":_vm.dropdown_icon,"alt":"Select job zone"}})]},proxy:true},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('p',{staticClass:"base-body-text mb-0"},[_c('span',{staticClass:"base-section-sub-title"},[_vm._v(" "+_vm._s(item.text))]),_vm._v(" "+_vm._s(item.sub_text)+" ")])])]}},{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('p',{staticClass:"base-body-text mb-0"},[_c('span',{staticClass:"base-section-sub-title"},[_vm._v(" "+_vm._s(item.text))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}]},[_vm._v(_vm._s(item.sub_text))])])])]}}])})],1),_c('div',{staticClass:"career-card mt-5 py-8"},[_c('div',[_c('p',{staticClass:"base-section-title"},[_vm._v("Careers")]),_c('next-move-career-list',{attrs:{"data":_vm.onet_interest_data}})],1)])]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12","md":"4"}},[_c('div',{staticStyle:{"width":"280px"}},[_c('v-img',{attrs:{"src":require('@/assets/images/next_move_intro.png')}})],1)]):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"mt-4"},[_c('p',{staticClass:"base-body-text mb-0"},[_c('em',[_vm._v(" *Click on a Career to Learn what they do. ")])])])],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }