





















































































import { NEXT_MOVE_JOB_ZONE_DETAILS } from "@/store/modules/my_next_move/constants";
import {
  JobZoneData,
  JobZoneDetails,
  JobZoneDetailsData
} from "@/store/modules/my_next_move/interfaces";
import Vue from "vue";
import { mapGetters } from "vuex";
import AppResourseNotFound from "../AppResourseNotFound.vue";

export default Vue.extend({
  name: "InterestProfileJobDetails",
  components: {
    AppResourseNotFound
  },
  data() {
    return {
      job_zones_detail_model: true,
      title: "",
      error: false
    };
  },
  mounted() {
    if (this.get_job_zones_title.includes(this.selected_job_zone))
      this.title = this.selected_job_zone;
    else this.error = true;
  },
  computed: {
    ...mapGetters("my_next_move", {
      selected_job_zone: NEXT_MOVE_JOB_ZONE_DETAILS
    }),
    get_job_zones_title() {
      return Object.values(JobZoneDetails); // Return all job zones title
    },
    // Return selected job zone details for selected job zone title
    selected_zone() {
      return JobZoneData.find((zone: JobZoneDetailsData) => {
        if (zone.title === this.title) {
          return zone;
        }
      });
    }
  },
  methods: {
    change_job_zone(title: string) {
      this.title = title;
    }
  }
});
