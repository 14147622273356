







































































































































import { IAppAssessment } from "@/interfaces";
import {
  APP_ASSESSMENTS,
  SITE_DIRECTION
} from "@/store/modules/common/constants";
import {
  INTERESTS_QUESTIONS_COMPLETED,
  NEXT_MOVE_ASSESSMENT_SCORES,
  NEXT_MOVE_DATA_ERROR,
  NEXT_MOVE_DATA_LOADING,
  NEXT_MOVE_INTERESTS_QUESTIONS,
  NEXT_MOVE_QUESTIONS_PAGINATION,
  NEXT_MOVE_QUESTION_INDEX,
  NEXT_MOVE_SELECTED_ASSESSMENT,
  NEXT_MOVE_SELECTED_ASSESSMENT_RESULT
} from "@/store/modules/my_next_move/constants";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import NextMoveLoading from "../NextMoveLoading.vue";
import AppResourseNotFound from "@/components/shared/AppResourseNotFound.vue";
import {
  APP_ASSESSMENT_ANSWERS,
  APP_ASSESSMENT_QUESTIONNAIRES,
  BULK_SUBMIT_ASSESSMENT_ANS,
  SUBMIT_APP_ASSESSMENT_ANSWERS
} from "@/store/modules/candidates/constants";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import { AssessmentQuestionnairesWithAns } from "@/store/modules/my_next_move/interfaces";
import AssessmentHeader from "@/components/assessments/AssessmentHeader.vue";
import { SiteDirections } from "@/store/modules/common/interfaces";
import {
  BulkSubmitAssessmentAnsPayload,
  SubmitAssessmentAnswersApiPayload
} from "@/store/modules/candidates/interfaces";
import { AssessmentAnswers } from "@/interfaces/app_assessments";
import { GET_ASSESSMENT_RESULT } from "@/store/modules/recruiter/constants";
import { GetAssessmentResultApiPayload } from "@/store/modules/recruiter/interfaces";
export default Vue.extend({
  components: { NextMoveLoading, AppResourseNotFound, AssessmentHeader },
  name: "NextMoveInterestsQuestions",
  data() {
    return {
      submit_ans_loading: false
    };
  },
  async mounted() {
    await this.process_init();
    if (this.calculate_completion_score === 100) {
      this.set_interests_question_completed(true);
    } else {
      this.set_interests_question_completed(false);
    }
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("my_next_move", {
      next_move_assessment_scores: NEXT_MOVE_ASSESSMENT_SCORES,
      data_loading: NEXT_MOVE_DATA_LOADING,
      data_error: NEXT_MOVE_DATA_ERROR,
      selected_assessment: NEXT_MOVE_SELECTED_ASSESSMENT,
      interests_questions: NEXT_MOVE_INTERESTS_QUESTIONS,
      questions_pagination: NEXT_MOVE_QUESTIONS_PAGINATION,
      question_index: NEXT_MOVE_QUESTION_INDEX
    }),
    ...mapGetters("common", {
      app_assessments: APP_ASSESSMENTS,
      get_site_direction: SITE_DIRECTION
    }),
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    }),
    calculate_completion_score(): number {
      const total = this.interests_questions.total;
      const answered = this.interests_questions.results.filter(
        (val: AssessmentQuestionnairesWithAns) => val.answer
      ).length;
      return Math.round((answered / total) * 100);
    }
  },
  methods: {
    ...mapMutations("my_next_move", {
      set_selected_assessment: NEXT_MOVE_SELECTED_ASSESSMENT,
      set_data_loading: NEXT_MOVE_DATA_LOADING,
      set_data_error: NEXT_MOVE_DATA_ERROR,
      set_interests_questions: NEXT_MOVE_INTERESTS_QUESTIONS,
      set_questions_pagination: NEXT_MOVE_QUESTIONS_PAGINATION,
      set_question_index: NEXT_MOVE_QUESTION_INDEX,
      set_interests_question_completed: INTERESTS_QUESTIONS_COMPLETED,
      set_selected_assessment_result: NEXT_MOVE_SELECTED_ASSESSMENT_RESULT
    }),
    ...mapActions("candidate", {
      fetch_assessment_questionnaries: APP_ASSESSMENT_QUESTIONNAIRES,
      submit_assessment_answers: SUBMIT_APP_ASSESSMENT_ANSWERS,
      fetch_assessment_answers: APP_ASSESSMENT_ANSWERS,
      bulk_submit_ans: BULK_SUBMIT_ASSESSMENT_ANS
    }),
    ...mapActions("recruiter", {
      get_assessment_result: GET_ASSESSMENT_RESULT
    }),
    // Process init
    async process_init() {
      this.set_data_loading(true); // Loading until data is fetched
      this.set_data_error(""); // Reset error
      const assessment = this.app_assessments.find(
        (val: IAppAssessment.Assessment) =>
          val.assessment_type === IAppAssessment.List.MY_NEXT_MOVE_ASSESSMENT
      );
      if (!assessment) {
        this.set_data_error("Assessment not found");
        this.set_data_loading(false);
        return;
      }
      this.set_selected_assessment(assessment);
      // Fetching questionaries
      const response = await this.fetch_assessment_questionnaries({
        assessment_id: assessment.id,
        page: this.questions_pagination,
        limit: 60
      });
      // Checking if response is valid or not
      // If not valid then setting error message
      if (!response || response.length <= 0) {
        this.set_data_error("Failed to get assessment record");
        this.set_data_loading(false);
        return;
      }
      response.results.forEach((val: AssessmentQuestionnairesWithAns) => {
        val.answer = null;
        val.answer_type = IAppAssessment.AnswerType.TEXT;
        val.answer_id = null;
      });
      const assessment_answers = await this.get_assessment_ans(
        response.results
      );
      if (assessment_answers.results.length > 0) {
        assessment_answers.results.forEach((val: AssessmentAnswers) => {
          const index = response.results.findIndex(
            (v: AssessmentQuestionnairesWithAns) => v.id === val.question_id
          );
          if (index >= 0) {
            response.results[index].answer = parseInt(val.answer);
            response.results[index].answer_type = val.answer_type;
            response.results[index].answer_id = val.id;
          }
        });
      }
      // Setting selected assessment questions
      this.set_question_index(this.question_index + 3);
      this.set_interests_questions(response);
      // Fetch assessment result
      const assessment_result_payload: GetAssessmentResultApiPayload = {
        assessment_id: assessment.id
      };
      const assessment_result = await this.get_assessment_result(
        assessment_result_payload
      );
      this.set_selected_assessment_result(assessment_result);
      this.set_data_loading(false);
    },
    /**
     * If assessment is selected and have my next move scores assessment answer_type
     * and assessment questions are fetched and assessment scores are set
     * then return true else false
     */
    check_data_existence() {
      if (
        this.selected_assessment &&
        this.selected_assessment.assessment_type ===
          IAppAssessment.List.MY_NEXT_MOVE_ASSESSMENT &&
        this.interests_questions.results.length > 0
      )
        return true;
      return false;
    },
    /**
     * Function to submit answers to the server in bulk
     */
    async submit_ans() {
      this.submit_ans_loading = true;
      // Filter answers that are answered by the user
      const answers = this.interests_questions.results.filter(
        (val: AssessmentQuestionnairesWithAns) => val.answer
      );
      if (answers.length <= 0) {
        this.submit_ans_loading = false;
        return;
      }
      // Payload to submit answers
      const submit_ans_payload: SubmitAssessmentAnswersApiPayload[] = [];
      // Looping through answers and creating payload
      answers.forEach((val: AssessmentQuestionnairesWithAns) => {
        const ans_type: unknown = val.answer_type;
        const _payload: SubmitAssessmentAnswersApiPayload = {
          assessment_id: this.selected_assessment.id,
          question_id: val.id,
          answer: val.answer,
          answer_type: ans_type as IAppAssessment.QuestionType,
          user_id: this.get_user_details.id
        };
        // If answer id is present then add it to payload
        if (val.answer_id) _payload.id = val.answer_id;
        submit_ans_payload.push(_payload);
      });
      const payload: BulkSubmitAssessmentAnsPayload = {
        assessment_id: this.selected_assessment.id,
        answers: submit_ans_payload
      };
      // Submitting answers to the server
      const resp: IAppAssessment.AssessmentAnswers[] | null =
        await this.bulk_submit_ans(payload);
      if (resp) {
        // Update answer id in store
        resp.forEach((val: AssessmentAnswers) => {
          const index = this.interests_questions.results.findIndex(
            (v: AssessmentQuestionnairesWithAns) => v.id === val.question_id
          );
          // If index is found then update answer id and answer type and answer
          if (index >= 0) {
            this.interests_questions.results[index].answer = parseInt(
              val.answer
            );
            this.interests_questions.results[index].answer_type =
              val.answer_type;
            this.interests_questions.results[index].answer_id = val.id;
          }
        });
        // Update assessment completion
        if (this.calculate_completion_score === 100) {
          this.set_interests_question_completed(true);
        } else {
          this.set_interests_question_completed(false);
        }
      }
      this.submit_ans_loading = false;
    },
    async get_assessment_ans(questions: AssessmentQuestionnairesWithAns[]) {
      // Get assessment answers of currently displayed questions
      const assessment_answers = await this.fetch_assessment_answers({
        assessment_id: this.selected_assessment.id,
        question_ids: questions.map(
          (val: AssessmentQuestionnairesWithAns) => val.id
        ),
        user_id: this.get_user_details.id,
        page: 0,
        limit: questions.length
      });
      return assessment_answers;
    }
  }
});
