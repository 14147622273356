














































































import { IAppAssessment, IOnetResults } from "@/interfaces";
import VueApexCharts from "vue-apexcharts";
import { ResultFields } from "@/interfaces/candidate/my_next_move";
import {
  NEXT_MOVE_ASSESSMENT_SCORES,
  NEXT_MOVE_DATA_ERROR,
  NEXT_MOVE_DATA_LOADING,
  NEXT_MOVE_SELECTED_ASSESSMENT,
  NEXT_MOVE_SELECTED_ASSESSMENT_RESULT
} from "@/store/modules/my_next_move/constants";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import NextMoveLoading from "../NextMoveLoading.vue";
import AppResourseNotFound from "@/components/shared/AppResourseNotFound.vue";
import { get_color_by_name } from "@/utils/colors";
import { MyNextMoveScores } from "@/store/modules/my_next_move/interfaces";
import { EmiratisationStatsGraphData } from "@/interfaces/recruiter/dashboard/recruiter_dashboard";

export default Vue.extend({
  components: {
    NextMoveLoading,
    AppResourseNotFound,
    "vue-apex-charts": VueApexCharts
  },
  name: "NextMoveResult",
  data() {
    return {
      title: "Presenting your Interest Profiler Results! 🎯",
      fields: [] as ResultFields[],
      graph_series: [] as EmiratisationStatsGraphData[],
      graph_options: {}
    };
  },
  created() {
    this.set_fields();
    this.graph_options = {
      colors: [
        "#3CE17E",
        "#FF4066",
        "#FF8473",
        "#FFB815",
        "#1C53F4",
        "#33BFFF"
      ],
      chart: {
        type: "bar",
        toolbar: {
          show: false
        }
      },
      tooltip: {
        enabled: false,
        position: "bottom"
      },
      dataLabels: {
        enabled: true,
        position: "top",
        hideOverflowingLabels: true,
        style: {
          color: "#fff",
          fontSize: this.$vuetify.breakpoint.mdAndUp ? "25px" : " 15px",
          fontFamily: "Roboto",
          fontWeight: 600
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: this.$vuetify.breakpoint.mdAndUp ? 25 : 10,
          borderRadiusApplication: "end",
          barHeight: "20px",
          distributed: true
        }
      },
      legend: {
        show: false
      },

      xaxis: {
        categories: [
          this.$t("my-next-move.interests.realistic").toString(),
          this.$t("my-next-move.interests.investigative").toString(),
          this.$t("my-next-move.interests.artistic").toString(),
          this.$t("my-next-move.interests.social").toString(),
          this.$t("my-next-move.interests.enterprising").toString(),
          this.$t("my-next-move.interests.conventional").toString()
        ],
        min: 0,
        max: 40,
        labels: {
          show: true,
          style: {
            colors: ["#334466"],
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: 500
          }
        }
      },
      yaxis: {
        labels: {
          show: false,
          formatter: function (value: number) {
            return value + "%";
          },
          style: {
            colors: ["#3F5073"],
            fontSize: "14px",
            fontFamily: "Roboto",
            fontWeight: 500
          }
        }
      },
      grid: {
        show: true,
        borderColor: "#ffffff",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      }
    };
  },
  async mounted() {
    await this.process_init();
  },
  computed: {
    IAppAssessment() {
      return IAppAssessment;
    },
    ...mapGetters("my_next_move", {
      next_move_assessment_scores: NEXT_MOVE_ASSESSMENT_SCORES,
      selected_assessment: NEXT_MOVE_SELECTED_ASSESSMENT,
      data_loading: NEXT_MOVE_DATA_LOADING,
      data_error: NEXT_MOVE_DATA_ERROR,
      selected_assessment_result: NEXT_MOVE_SELECTED_ASSESSMENT_RESULT
    })
  },
  methods: {
    ...mapMutations("my_next_move", {
      set_data_loading: NEXT_MOVE_DATA_LOADING,
      set_data_error: NEXT_MOVE_DATA_ERROR
    }),
    // Set field
    set_fields() {
      this.fields = [
        {
          label: this.$t("my-next-move.interests.realistic").toString(),
          color: get_color_by_name("success")
        },
        {
          label: this.$t("my-next-move.interests.investigative").toString(),
          color: get_color_by_name("danger")
        },
        {
          label: this.$t("my-next-move.interests.artistic").toString(),
          color: get_color_by_name("danger-light")
        },
        {
          label: this.$t("my-next-move.interests.social").toString(),
          color: get_color_by_name("warning")
        },
        {
          label: this.$t("my-next-move.interests.enterprising").toString(),
          color: get_color_by_name("primary")
        },
        {
          label: this.$t("my-next-move.interests.conventional").toString(),
          color: get_color_by_name("primary-light")
        }
      ];
    },
    // Process init
    async process_init() {
      if (
        this.selected_assessment.assessment_type ===
        IAppAssessment.List.MY_NEXT_MOVE_SCORES_ASSESSMENT
      ) {
        const scores = this.next_move_assessment_scores as MyNextMoveScores;
        this.graph_series = [
          {
            name: this.$t("recruiter.dashboard.emiratisation-title").toString(),
            data: [
              parseInt(scores.realistic),
              parseInt(scores.investigative),
              parseInt(scores.artistic),
              parseInt(scores.social),
              parseInt(scores.enterprising),
              parseInt(scores.conventional)
            ]
          }
        ];
      } else if (
        this.selected_assessment.assessment_type ===
        IAppAssessment.List.MY_NEXT_MOVE_ASSESSMENT
      ) {
        if (!this.selected_assessment_result) {
          this.set_data_error("Assessment Result Not Found");
          return;
        }
        const result = this
          .selected_assessment_result as IAppAssessment.AssessmentResults;
        const _scores = result.result as IOnetResults.Root;
        const scores = _scores.data.scores;
        this.graph_series = [
          {
            name: this.$t("recruiter.dashboard.emiratisation-title").toString(),
            data: []
          }
        ];
        for (const val of scores) {
          this.graph_series[0].data.push(val);
        }
      } else {
        this.set_data_error("Invalid Assessment");
      }
    }
  }
});
