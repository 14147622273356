











































































































import { APP_ASSESSMENTS } from "@/store/modules/common/constants";
import {
  NEXT_MOVE_DATA_ERROR,
  NEXT_MOVE_DATA_LOADING,
  NEXT_MOVE_SELECTED_ASSESSMENT_RESULT,
  NEXT_MOVE_SELECTED_JOB_ZONE,
  RESET_MY_NEXT_MOVE_STATE
} from "@/store/modules/my_next_move/constants";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import NextMoveLoading from "../NextMoveLoading.vue";
import AppResourseNotFound from "@/components/shared/AppResourseNotFound.vue";
import NextMoveCareerList from "@/components/candidate/app_assessments/my_next_move/careers/NextMoveCareerList.vue";

export default Vue.extend({
  components: { NextMoveLoading, AppResourseNotFound, NextMoveCareerList },
  name: "NextMoveCareers",
  data() {
    return {
      title: "Careers that fit your Interests and Prepration level",
      job_zones: [
        {
          text: "Job Zone One ",
          sub_text: "(Little or No Job Prepration Needed)",
          value: 1
        },
        {
          text: "Job Zone Two ",
          sub_text: "(Some Prepration Need)",
          value: 2
        },
        {
          text: "Job Zone Third ",
          sub_text: "(Medium Prepration Need)",
          value: 3
        },
        {
          text: "Job Zone Fourth ",
          sub_text: "(High Prepration Need)",
          value: 4
        },
        {
          text: "Job Zone Fifth ",
          sub_text: "(Extensive Prepration Need)",
          value: 5
        }
      ],

      dropdown_icon: require("@/assets/icons/linear/direction-down.svg"),
      flag_icon: require("@/assets/icons/linear/flag.svg")
    };
  },
  computed: {
    ...mapGetters("my_next_move", {
      data_loading: NEXT_MOVE_DATA_LOADING,
      data_error: NEXT_MOVE_DATA_ERROR,
      selected_job_zone: NEXT_MOVE_SELECTED_JOB_ZONE,
      selected_assessment_result: NEXT_MOVE_SELECTED_ASSESSMENT_RESULT
    }),
    ...mapGetters("common", {
      app_assessments: APP_ASSESSMENTS
    }),
    best_fit_careers: {
      get() {
        return this.selected_assessment_result.result.data.Best[
          this.selected_job_zone
        ];
      }
    },
    great_fit_careers: {
      get() {
        return this.selected_assessment_result.result.data.Great[
          this.selected_job_zone
        ];
      }
    },
    good_fit_careers: {
      get() {
        return this.selected_assessment_result.result.data.Good[
          this.selected_job_zone
        ];
      }
    }
  },
  methods: {
    ...mapMutations("my_next_move", {
      set_data_loading: NEXT_MOVE_DATA_LOADING,
      set_data_error: NEXT_MOVE_DATA_ERROR,
      set_selected_job_zone: NEXT_MOVE_SELECTED_JOB_ZONE,
      reset_next_move_state: RESET_MY_NEXT_MOVE_STATE
    })
  }
});
