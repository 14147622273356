












































































import Vue from "vue";
import { mapGetters } from "vuex";

import NextMoveHeader from "@/components/candidate/app_assessments/my_next_move/NextMoveHeader.vue";
import { NEXT_MOVE_ASSESSMENT_STATE } from "@/store/modules/my_next_move/constants";
import { MyNextMoveAssessmentStates } from "@/store/modules/my_next_move/interfaces";
import IntroMyNextMoveStart from "@/components/candidate/app_assessments/my_next_move/start/NextMoveStart.vue";
import NextMoveInterestsScores from "@/components/candidate/app_assessments/my_next_move/interests/NextMoveInterestsScores.vue";
import NextMoveFooter from "@/components/candidate/app_assessments/my_next_move/NextMoveFooter.vue";
import NextMoveStartDescription from "@/components/candidate/app_assessments/my_next_move/start/NextMoveStartDescription.vue";
import NextMoveResults from "@/components/candidate/app_assessments/my_next_move/results/NextMoveResult.vue";
import NextMoveJobZones from "@/components/candidate/app_assessments/my_next_move/job_zones/NextMoveJobZones.vue";
import NextMoveCareers from "@/components/candidate/app_assessments/my_next_move/careers/NextMoveCareers.vue";
import NextMoveInterestsQuestions from "@/components/candidate/app_assessments/my_next_move/interests/NextMoveInterestsQuestions.vue";
import NextMoveMoreCareers from "@/components/candidate/app_assessments/my_next_move/careers/NextMoveMoreCareers.vue";

export default Vue.extend({
  components: {
    NextMoveHeader,
    IntroMyNextMoveStart,
    NextMoveInterestsScores,
    NextMoveFooter,
    NextMoveStartDescription,
    NextMoveJobZones,
    NextMoveResults,
    NextMoveCareers,
    NextMoveInterestsQuestions,
    NextMoveMoreCareers
  },
  name: "MyNextMove",
  computed: {
    MyNextMoveAssessmentStates() {
      return MyNextMoveAssessmentStates;
    },
    ...mapGetters("my_next_move", {
      next_move_assessment_state: NEXT_MOVE_ASSESSMENT_STATE
    })
  }
});
