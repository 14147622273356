var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('next-move-header'),_c('div',{staticClass:"base-card",staticStyle:{"position":"relative"}},[(_vm.next_move_assessment_state === _vm.MyNextMoveAssessmentStates.START)?[_c('intro-my-next-move-start')]:_vm._e(),(
        _vm.next_move_assessment_state ===
        _vm.MyNextMoveAssessmentStates.START_DESCRIPTION
      )?[_c('next-move-start-description')]:(
        _vm.next_move_assessment_state ===
        _vm.MyNextMoveAssessmentStates.INTERESTS_SCORES
      )?[_c('next-move-interests-scores')]:(
        _vm.next_move_assessment_state ===
        _vm.MyNextMoveAssessmentStates.INTERESTS_QUESTIONS
      )?[_c('next-move-interests-questions')]:(
        _vm.next_move_assessment_state === _vm.MyNextMoveAssessmentStates.RESULTS
      )?[_c('next-move-results')]:(
        _vm.next_move_assessment_state === _vm.MyNextMoveAssessmentStates.JOB_ZONES
      )?[_c('next-move-job-zones')]:(
        _vm.next_move_assessment_state === _vm.MyNextMoveAssessmentStates.CAREERS
      )?[_c('next-move-careers')]:(
        _vm.next_move_assessment_state === _vm.MyNextMoveAssessmentStates.MORE_CAREERS
      )?[_c('next-move-more-careers')]:_vm._e()],2),_c('next-move-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }